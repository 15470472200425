import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import SEO from '../../components/Seo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEO description="Using TypeScript with React functional components." title="Functional Components" keywords={['functional components', 'sfc', 'stateless functional components']} mdxType="SEO" />
    <h1 {...{
      "id": "functional-components"
    }}>{`Functional Components`}</h1>
    <p>{`Use the `}<inlineCode parentName="p">{`React.FC`}</inlineCode>{` type and define the generic with your props type.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type HelloWorldProps = {
  userName: string;
};

const HelloWorld: React.FC<HelloWorldProps> = ({ children, userName }) => (
  <div>
    <p>Hello, {userName}</p>
    {children}
  </div>
);
`}</code></pre>
    <h2 {...{
      "id": "functional-components-with-static-properties"
    }}>{`Functional Components with "Static" Properties`}</h2>
    <p>{`If you need to use a "static" property with your functional component (say, to define a router), you can do the following:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-typescript"
      }}>{`type Router = {
  route: string;
};
const someFancyRouter: Router = { route: '/hello/world' };

type HelloWorldProps = {
  userName: string;
};

type HelloWorldComponent = React.FC<HelloWorldProps> & { router: Router };

const HelloWorld: HelloWorldComponent = ({ children, userName }) => (
  <div>
    <p>Hello, {userName}</p>
    {children}
  </div>
);
HelloWorld.router = someFancyRouter;
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=9lUN3sqAjQQ"
      }}>{`This video`}</a>{` explains another example of typing a functional component.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      